<template>
  <div class="date-line">
    <a-tooltip placement="top" :title="courseStart">
      <div class="time-dots" :style="{ left: `${startEndTimeTansform * 100}%` }" />
    </a-tooltip>
    <a-tooltip placement="top" :title="courseEnd">
      <div class="time-dots" :style="{ left: `${(1 - startEndTimeTansform) * 100}%` }" />
    </a-tooltip>
    <div
      v-for="(times, index) in timeList"
      class="student-progress"
      :key="index"
      :style="{
        width: `${getPointInfo(times).width}%`,
        left: `${getPointInfo(times).left}%`,
      }"
    >
      <a-tooltip placement="top" :title="`${$moment(times[0].time).format('HH:mm')} ${'进入教室'}`">
        <div class="student-time" :style="{ left: 0, transform: 'translateX(-50%)' }" />
      </a-tooltip>
      <a-tooltip
        v-if="times[1] && times[1].time"
        placement="top"
        :title="`${$moment(times[1].time).format('HH:mm')} ${'离开教室'}`"
      >
        <div class="student-time" :style="{ right: 0, transform: 'translateX(50%)' }" />
      </a-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    details: {
      type: Array,
      default: () => [],
    },
    startTime: {
      type: Number,
      default: 0,
    },
    endTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // 上下课前后预留的进度条的百分比
      startEndTimeTansform: 0.1,
    };
  },

  computed: {
    // 上课时间文字转换
    courseStart() {
      return `${this.$moment(this.startTime).format('HH:mm')} ${'上课时间'}`;
    },
    // 下课时间文字转换
    courseEnd() {
      return `${this.$moment(this.endTime).format('HH:mm')} ${'下课时间'}`;
    },
    // 时间轴总长度（微秒记）
    lineAll() {
      return (this.endTime - this.startTime) / (1 - 2 * this.startEndTimeTansform);
    },
    // 时间轴起点的时间戳
    lineStart() {
      return this.startTime - this.startEndTimeTansform * this.lineAll;
    },
    // 时间轴起点的时间戳
    lineEnd() {
      return this.lineStart + this.lineAll;
    },
    // 将数组转换成 [进，出]形式的多维数组
    timeList() {
      const l = [];

      this.details.forEach((item, index) => {
        // eslint-disable-next-line no-param-reassign
        item.time = new Date(item.time).getTime();
        if (index % 2 === 0) return;
        l.push([this.details[index - 1], this.details[index]]);
      });

      return l;
    },
  },
  methods: {
    getPointInfo(times) {
      // 用于超出滚动条后就以滚动条末首作为最大width和left
      const lm1 = times[0].time <= this.lineStart ? this.lineStart : times[0].time;

      const lm2 = times[1].time > this.lineEnd ? this.lineEnd : times[1].time;

      const left = +((lm1 - this.lineStart) / this.lineAll) * 100;

      let width;

      if (!times[1]) {
        width = 0;
      } else {
        width = ((lm2 - lm1) / this.lineAll) * 100;
      }

      return { left, width };
    },
  },
};
</script>
<style lang="less" scoped>
.date-line {
  position: relative;
  height: 8px;
  background: #e5e5e5;
  border-radius: 4px;
  width: 300px;
  .time-dots {
    position: absolute;
    top: 50%;
    width: 10px;
    height: 10px;
    background: #ff6468;
    opacity: 1;
    border-radius: 5px;
    transform: translateY(-50%) translateX(-50%);
    z-index: 9;
    cursor: pointer;
  }
  .student-progress {
    position: absolute;
    height: 100%;
    background: #04cb94;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    border-radius: 5px;
  }
  .student-time {
    cursor: pointer;
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-top: 12px solid #04cb94;
    opacity: 1;
    border-left: 6px solid transparent;
    position: absolute;
    top: -14px;
    position: absolute;
  }
}
</style>
